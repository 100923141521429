<!-- @format -->

<template>
  <v-row justify="center" align="center">
    <v-col cols="12" sm="8" md="6">
      <h1>
        Translator grecko-polski wiadomości dla klientów CCC na rynku greckim
      </h1>
      <div class="mt-6">
        <h3>Autor/ka wiadomości: {{ greekName }} / {{ polishName }}</h3>
      </div>
      <div class="mt-6">
        <h3>Temat wiadomości: {{ greekSubject }} / {{ polishSubject }}</h3>
      </div>
      <div class="mt-6">
        <h3>Numer telefonu: {{ greekPhone }}</h3>
      </div>
      <div class="mt-6">
        <h3>Numer zamówienia: {{ greekOrder }}</h3>
      </div>
      <div class="mt-6">
        <h3>Treść wiadomości od {{ greekMail }} w języku greckim:</h3>
        <p class="mt-2">{{ greekMessage }}</p>
      </div>
      <div class="mt-6">
        <h3>
          Treść wiadomości od {{ greekMail }} przetłumaczona na język polski:
        </h3>
        <p class="mt-2">{{ polishMessage }}</p>
      </div>

      <div class="break-line"></div>

      <div class="mt-6">
        <h3>Treść Twojej odpowiedzi w języku polskim:</h3>
      </div>
      <v-text-field
        v-model="polishWelcome"
        label="Przywitanie po polsku"
        hide-details="auto"
      ></v-text-field>
      <v-textarea
        v-model="polishResponse"
        name="input-7-1"
        label="Odpowiedź po polsku"
        hint="Wpisz treść odpowiedzi w języku polskim"
      ></v-textarea>
      <v-text-field
        v-model="polishFarewell"
        label="Pożegnanie po polsku"
        hide-details="auto"
      ></v-text-field>
      <div class="d-flex justify-center">
        <v-btn
          class="ma-2 white--text"
          :loading="loadingTranslate"
          :disabled="loadingTranslate"
          color="blue-grey"
          @click="handleClickTranslateGreek"
        >
          Przetłumacz na grecki
        </v-btn>
      </div>
      <div class="mt-6">
        <h3>
          Treść wiadomości przetłumaczona na język grecki do wysłania klientowi
          pod adres {{ greekMail }}:
        </h3>
      </div>
      <v-text-field
        v-model="greekWelcome"
        label="Przywitanie po grecku"
        hide-details="auto"
      ></v-text-field>
      <v-textarea
        name="input-7-1"
        label="Odpowiedź po grecku"
        :value="greekResponse"
        v-model="greekResponse"
        hint="Edytuj tylko jeżeli widzisz błąd w tłumaczeniu"
      ></v-textarea>
      <v-text-field
        v-model="greekFarewell"
        label="Pożegnanie po grecku"
        hide-details="auto"
      ></v-text-field>
      <div class="d-flex justify-center" v-if="uSendEnabled">
        <v-btn
          class="ma-2"
          :loading="loadingSend"
          :disabled="loadingSend"
          color="primary"
          @click="handleClickSendMail"
        >
          Wyślij do {{ greekMail }}
        </v-btn>
        <v-snackbar v-model="snackbar">
          {{ infoText }}
          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
              Zamknij
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// import firebase from "@/firebaseinit";
import { startFunctionsRequest } from '@/firebaseinit';
import { UnleashClient } from 'unleash-proxy-client';
const greekUtils = require('greek-utils');

export const unleash = new UnleashClient({
  url: 'https://unleash-proxy-hby2elejuq-lm.a.run.app/proxy',
  clientKey: 'qsrM4FbZsg2Dng76esMkXArNmb5945',
  appName: 'ccc-translate',
  refreshInterval: 60
});
unleash.updateContext({ clientName: 'prod.ccc.translate' });
unleash.start();

export default {
  computed: {},
  components: {},
  watch: {
    uSendEnabled: function(val) {
      this.uSendEnabled = val;
    }
  },
  created() {
    unleash.on('update', () => {
      this.checkSendEnabled();
    });
  },
  beforeDestroy() {
    unleash.stop();
  },
  data: () => ({
    greekSubject: 'brak tematu',
    polishSubject: 'brak tematu',
    greekName: 'brak imienia i nazwiska',
    polishName: 'brak imienia i nazwiska',
    greekPhone: 'brak nr telefonu',
    greekOrder: 'brak nr zamówienia',
    greekMail: 'brak maila',
    greekMessage: 'brak wiadomości',
    polishMessage: 'brak wiadomości',
    polishWelcome: '',
    polishResponse: '',
    polishFarewell: '',
    greekWelcome: '',
    greekResponse: '',
    greekFarewell: '',
    loader: null,
    loadingTranslate: false,
    loadingSend: false,
    uSendEnabled: true,
    infoText: '',
    snackbar: false,
    footer_1:
      'Σε περίπτωση που έχετε περαιτέρω ερωτήσεις, παρακαλούμε επικοινωνήστε μαζί μας μέσω της φόρμας επικοινωνίας που θα βρείτε στην σελίδα: https://ccc.eu/gr/epikoinonia',
    footer_2: 'Παρακαλούμε μην απαντάτε σε αυτό το email.',
    footer_3: 'Θερμοί χαιρετισμοί',
    footer_4: 'Ομάδα Εξυπηρέτησης Πελατών CCC',
    footer_5:
      'Το εν λόγω μήνυμα όπως και όλα τα συνημμένα είναι εμπιστευτικά και προορίζονται αποκλειστικά για την ενημέρωση του παραλήπτη. ',
    footer_6:
      'Εάν λάβατε αυτό το μήνυμα κατά λάθος, η χρήση, η προώθηση ή η αντιγραφή του με οποιονδήποτε τρόπο απαγορεύεται από το νόμο. Παρακαλείστε επίσης να ενημερώσετε τον αποστολέα.'
  }),
  mounted() {
    if (window.location.search) {
      this.setDataFromUrl('em', 'greekMail');
      this.setDataFromUrl('tel', 'greekPhone');
      this.setDataFromUrl('ord', 'greekOrder');
      this.setDataFromUrlwithTranslate('txt', 'greekMessage', 'polishMessage');
      this.setDataFromUrlwithTranslate('sub', 'greekSubject', 'polishSubject');
      this.setDataFromUrlwithTranslate('name', 'greekName', 'polishName');
    }
  },
  methods: {
    decode: str => Buffer.from(str, 'base64').toString('utf-8'),
    encode: str => Buffer.from(str, 'utf-8').toString('base64'),
    translateText: async (message, from, to) => {
      if (from === 'el') {
        message = greekUtils.toGreek(message);
      }
      const response = await fetch(
        `https://translation.googleapis.com/language/translate/v2?key=AIzaSyBLM2fRIx1ntKPrmL_x4rjMfE6rTJngSgM&q=${encodeURIComponent(
          message
        )}&source=${from}&target=${to}&format=text`
      );
      return response.json();
    },
    checkSendEnabled() {
      const sendEnabled = unleash.isEnabled('prod.ccc.translate.sendEnabled');
      const variant = unleash.getVariant('prod.ccc.translate.sendEnabled');
      console.log('checkSendEnabled', sendEnabled, variant);
      console.log('sendEnabled', sendEnabled);
      this.uSendEnabled = sendEnabled;
      return sendEnabled;
    },
    handleClickTranslateGreek() {
      this.loadingTranslate = true;
      this.translateText(this.polishWelcome, 'pl', 'el').then(resp => {
        this.greekWelcome = resp.data.translations[0].translatedText;
      });
      this.translateText(this.polishResponse, 'pl', 'el').then(resp => {
        this.greekResponse = resp.data.translations[0].translatedText;
      });
      this.translateText(this.polishFarewell, 'pl', 'el').then(resp => {
        this.greekFarewell = resp.data.translations[0].translatedText;
      });
      this.loadingTranslate = false;
    },
    handleClickSendMail() {
      this.loadingSend = true;
      const email = {
        from: '"info.el@ccc.eu" <info.el@ccc.eu>',
        to: `${this.greekMail}, ${this.greekMail}`,
        subject: 'RE: Ειδοποίηση', // Subject line
        // plain text body
        text: `${this.greekWelcome}\n${this.greekResponse}\n${this.greekFarewell}\n---\n${this.footer_1}\n${this.footer_2}\n\n${this.footer_3}\n${this.footer_4}\n\n${this.footer_5}${this.footer_6}`,
        //@ts-ignore
        html: `<p>${this.greekWelcome}</p><p>${this.greekResponse}</p><p>${this.greekFarewell}</p><br>---<p>${this.footer_1}</p><p>${this.footer_2}</p><p>${this.footer_3}</p><p>${this.footer_4}</p><p>${this.footer_5}${this.footer_6}</p>` // html body
      };
      startFunctionsRequest(email).then(result => {
        // Read result of the Cloud Function.
        console.log(`result = ${JSON.stringify(result)}`);
        console.log('Email request sent');
        this.loadingSend = false;
        if (result.sent) {
          this.infoText = 'Email został wysłany';
        } else {
          this.infoText = 'Coś poszło nie tak, spróbuj ponownie';
        }
        this.snackbar = true;
      });
      // callSendMessage(email);
    },
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    setDataFromUrl(query, dataField) {
      if (this.getParameterByName(query)) {
        const urlParams = new URLSearchParams(window.location.search);
        const dataBase64 = urlParams.get(query);
        const decodedData = this.decode(dataBase64);
        this[dataField] = decodedData;
      }
    },
    setDataFromUrlwithTranslate(query, dataFieldFrom, dataFieldTo) {
      if (this.getParameterByName(query)) {
        const urlParams = new URLSearchParams(window.location.search);
        const dataBase64 = urlParams.get(query).replace(/ /g, '+');
        let decodedData = this.decode(dataBase64);
        const doc = new DOMParser().parseFromString(decodedData, 'text/html');
        decodedData = doc.documentElement.textContent;
        this[dataFieldFrom] = decodedData;
        this.translateText(decodedData, 'el', 'pl').then(resp => {
          this[dataFieldTo] = resp.data.translations[0].translatedText;
        });
      }
    }
  }
};
// api key AIzaSyBLM2fRIx1ntKPrmL_x4rjMfE6rTJngSgM
// https://ccc-translation-gr.web.app/?em=<base64-email>&txt=<base64-message>
</script>

<style scoped>
.break-line {
  margin: 100px 50px 100px 50px;
  border-bottom: 2px solid #000000;
}
</style>
